import React, { useState, useEffect, useLayoutEffect, useRef, useCallback, useMemo } from 'react'
import { inRange ,random } from 'lodash'
import useMounted from 'hooks/use-mounted'
import { useWindowSize, useInterval } from 'rooks'
import { useInView } from 'react-intersection-observer'
import { intersectThresholds } from '../util'

const triangleStyleObj = {
  transition: `all 2000ms ease-in-out`,
}

const generateGroupOpacityMap = () => {
  const transStart = random(0,3),
        transEndMax = 4 - transStart,
        transEnd = random(transStart, transStart + transEndMax),
        transRange = [transStart, transEnd]

  const map = []

  for (let i = 0; i < 4; i++) {
    map[i] = inRange(i, ...transRange) ? 0 : 1
  }

  return map
}

const SquareGroup = ({
  translate = [0, 0],
  fill = `rgba(0,0,0,0)`,
  isVisible,
  className = ``,
  style = {},
}) => {
  const [opacityMap, setOpacityMap] = useState(generateGroupOpacityMap())
  const updateOpacityMap = useCallback(() => setOpacityMap(() => generateGroupOpacityMap()), [])

  const { start: startInterval, stop: stopInterval } = useInterval(
    updateOpacityMap,
    3000
  )

  useEffect(() => {
    isVisible ? startInterval() : stopInterval()

    return () => {
      stopInterval()
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <g
      className={`square ${className ? `fill-current stroke-current ${className}` : ``}`}
      transform={`translate(${translate[0]}, ${translate[1]})`}
      // style={{ fill, stroke: fill, strokeWidth: 1 }}
    >
      <path
        //  className="top-left"
        style={{ opacity: opacityMap[0], ...triangleStyleObj }}
        d="M 0,7 7,7 7,0 Z"
      />
      <path
        //  className="top-right"
        style={{ opacity: opacityMap[1], ...triangleStyleObj }}
        d="m 7,0 0,7 7,0 z"
      />
      <path
        //  className="bottom-left"
        style={{ opacity: opacityMap[2], ...triangleStyleObj }}
        d="M 14,7 7,7 7,14 Z"
      />
      <path
        //  className="bottom-right"
        style={{ opacity: opacityMap[3], ...triangleStyleObj }}
        d="M 7,14 7,7 0,7 Z"
      />
    </g>
  )
}

const generateRange = ( rangeLen, total ) => {
  let rangeStart = random(0, total - rangeLen - 1)
  let rangeEnd = rangeStart + rangeLen

  return [rangeStart, rangeEnd]
}

const SquaresSVG = ({
  contentsRef,
  fillColor = `gray-600`,
  activeFillColor = `brand`, 
  width = null,
  rows = 3,
  padding = 15,
  minWidthPerSquare = 36,
  id,
  children,
  ...props
}) => {
  const initWidth = useRef(width)

  const [canvasWidth, setCanvasWidth] = useState(initWidth.current || 0)
  
  const { innerWidth: winWidth, innerHeight: winHeight } = useWindowSize()
  
  const [rootNode, isVisible, intersectionEntry] = useInView({
    threshold: intersectThresholds,
  })

  // if (HOCroot) {
  //   rootNode(HOCroot)
  // }

  const [greyRange, setGreyRange] = useState([0, 0])

  const actualWidth = 12
  const perRow = Math.max(
    1,
    Math.floor((canvasWidth - padding * 2) / minWidthPerSquare)
  )
  const widthPerGroup = (canvasWidth - padding * 2) / perRow
  const height = Math.max(widthPerGroup * rows + padding * 2, 0)
  const totalGroups = rows * perRow
  const greyRangeLen = Math.ceil(totalGroups / 2.5)

  /** 
   * Scroll feedback effect attempt
   */
    // const [positionProgress, setPositionProgress] = useState(0)
  
    // const intersectRatio = useMemo(
    //   () => (intersectionEntry && intersectionEntry.intersectionRatio) || 0,
    //   [intersectionEntry]
    // )

  // useEffect(() => {
  //   if ( ! intersectionEntry ) {
  //     return
  //   }
    
  //   const { isIntersecting } = intersectionEntry

  //   function handleScroll() {
  //     raf(() => {
  //       const { intersectionRect } = intersectionEntry
        
  //       // if (id === `philosophy`) {
  //       //   console.log(`[${id}] intersectionRect: `, intersectionRect)
  //       // }

  //       setPositionProgress( Math.max(intersectionRect.top, 0) / winHeight )
  //     })
  //   }

  //   if (isIntersecting) {
  //     window.addEventListener(`scroll`, handleScroll)
  //   } else {
  //     window.removeEventListener(`scroll`, handleScroll)
  //   }
    
  //   return () => window.removeEventListener(`scroll`, handleScroll)
  // }, [id, intersectionEntry, isVisible, winHeight])

  // useEffect(() => {
  //   // if (id === `philosophy`) {
  //   //   console.log(`[${id}] positionProgress: `, positionProgress)
  //   // }
  // }, [id, positionProgress])

  useLayoutEffect(() => {
    if ( initWidth.current !== null ) {
      return
    }

    if (contentsRef.current) {
      setCanvasWidth(contentsRef.current.offsetWidth)
    }
  }, [initWidth, winWidth, setCanvasWidth, contentsRef])

  const updateGreyRange = useCallback(() =>
    setGreyRange(() => generateRange(greyRangeLen, totalGroups)), [greyRangeLen, totalGroups])

  const { start: startInterval, stop: stopInterval } = useInterval(
    updateGreyRange,
    6000
  )

  useEffect(() => {
    if (isVisible) {
      updateGreyRange()
      startInterval()
    } else {
      stopInterval()
    }

    return stopInterval
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, updateGreyRange])

  function renderGroups() {
    const groups = []
    let transY, transX

    let idx = 0

    for (let c = 0; c < perRow; c++) {
      transX = padding + c * widthPerGroup + (widthPerGroup - actualWidth) / 2

      for (let r = 0; r < rows; r++) {
        transY = padding + r * widthPerGroup + (widthPerGroup - actualWidth) / 2

        let fill = `transparent`
        if (inRange(idx, ...greyRange)) {
          // fill = [fillColor, activeFillColor][random(0, 1)]
          fill = fillColor
        }

        // if ( inRange( idx, ...activeRange ) ) {
        //   fill = '#ec008c'
        // }

        groups.push(
          <SquareGroup
            className={fill ? `text-${fill}` : ``}
            isVisible={isVisible}
            key={`group-${r}x${c}`}
            translate={[transX, transY]}
            // fill={fill}
            // stroke={fill}
          />
        )
        
        idx++
      }
    }

    return groups
  }

  return (
    <svg
      ref={rootNode}
      width={canvasWidth}
      height={height}
      viewBox={`0 0 ${canvasWidth} ${height}`}
      {...props}
    >
      {renderGroups()}
    </svg>
  )
}

export const SquaresBackgroundSVG = ({ children, className = ``, svgClassName = ``, ...props }) => {
  const contentsRef = useRef()
  const mounted = useMounted()

  return (
    <div className={`relative svgbg ${className}`}>
      {(mounted && <SquaresSVG contentsRef={contentsRef} className={`${svgClassName} absolute bottom-0`} {...props} />) || null}

      <div ref={contentsRef} className="relative overflow-hidden">
        {children}
      </div>
    </div>
  )
}

export default SquaresSVG
