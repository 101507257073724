import React, { useEffect, useRef, useCallback } from "react";
import { uniqueId } from 'lodash'
import 'styles/cube.scss'

import Icon from 'components/Icon'
import Icon_ES6 from 'icons/es6.svg'
import Icon_ES6_Onetone from 'icons/es6-onetone.svg'
import Icon_GraphQL from 'icons/graphql.svg'
import Icon_GraphQL_Onetone from 'icons/graphql-onetone.svg'
import Icon_Gatsby from 'icons/gatsby.svg'
import Icon_Gatsby_Onetone from 'icons/gatsby-onetone.svg'
import Icon_Nuxt from 'icons/nuxt.svg'
import Icon_Nuxt_Onetone from 'icons/nuxt-onetone.svg'
import Icon_SLS from 'icons/serverless.svg'
import Icon_SLS_Onetone from 'icons/serverless-onetone.svg'
import Icon_Bootstrap from 'icons/bootstrap.svg'
import Icon_Bootstrap_Onetone from 'icons/bootstrap-onetone.svg'
import Icon_React from 'icons/react.svg'
import Icon_React_Onetone from 'icons/react-onetone.svg'
import Icon_Sass from 'icons/sass.svg'
import Icon_Sass_Onetone from 'icons/sass-onetone.svg'
import Icon_Node from 'icons/nodejs-icon.svg'
import Icon_Node_Onetone from 'icons/nodejs-icon-onetone.svg'
import Icon_WP from 'icons/wordpress-light.svg'
import Icon_WP_Onetone from 'icons/wordpress-onetone.svg'
import Icon_Woocommerce from 'icons/woocommerce.svg'
import Icon_Woocommerce_Onetone from 'icons/woocommerce-onetone.svg'
import Icon_Laravel from 'icons/laravel.svg'
import Icon_Laravel_Onetone from 'icons/laravel-onetone.svg'
import Icon_Vue from 'icons/vue.svg'
import Icon_Vue_Onetone from 'icons/vue-onetone.svg'
import Icon_Webpack from 'icons/webpack.svg'
import Icon_Webpack_Onetone from 'icons/webpack-onetone.svg'
import Icon_Tailwind from 'icons/tailwindcss-icon.svg'
import Icon_Tailwind_Onetone from 'icons/tailwindcss-icon-onetone.svg'
import Icon_JamStack from 'icons/jamstack2.svg'
import Icon_JamStack_Onetone from 'icons/jamstack2-onetone.svg'
import Icon_Jest from 'icons/jest.svg'
import Icon_Jest_Onetone from 'icons/jest-onetone.svg'

const layers = {
	u: {corners: [0, 1, 3, 2], edges: [0, 1, 3, 2]},
	f: {corners: [1, 0, 4, 5], edges: [0, 8, 4, 9]},
	r: {corners: [0, 2, 6, 4], edges: [6, 8, 2, 10]},
	l: {corners: [3, 1, 5, 7], edges: [1, 9, 5, 11]},
	b: {corners: [2, 3, 7, 6], edges: [3, 11, 7, 10]},
	d: {corners: [4, 6, 7, 5], edges: [4, 6, 7, 5]}
};


/** Adds classes to cubies to start animation. */
function move(turn) { // turn examples: 'r1', 'd2', 'u3'
	var side = turn[0];
	var layer = layers[side];
  var m = document.querySelector('.cubie-middle-' + side);
  var cubies = [m.parentNode];
  
  for(var i=0; i<layer.corners.length; ++i) {
    try {
      var c = document.querySelector('.cubie-corner-position-' + layer.corners[i]);
      cubies.push(c.parentNode);
    } catch(e) {
    }
  }
  
  for(var i=0; i<layer.edges.length; ++i) {
    try {
      var e = document.querySelector('.cubie-edge-position-' + layer.edges[i]);
      cubies.push(e.parentNode);
    } catch(e) {
    }
  }
  
  for(var i=0; i<cubies.length; ++i) {
    cubies[i].classList.add('turn');
    cubies[i].classList.add('turn-' + turn);
  }
}


/**	Updates classes of cubie. This should be called on completion of
	animation for every cubie that was involved in animation. */
function updateCubie(e) {
  if (e.target.tagName.toLowerCase() === 'use' ) {
    e.stopPropagation();
    return;
  }

	var match = this.className.match(/turn\-(..)/);
	this.classList.remove('turn');
	this.classList.remove(match[0]);
	
	var step = +match[1][1];
	var side = match[1][0];
	var layer = layers[side];
	var div = this.children[0];
	
	var re = /(cubie-corner-position-)(\d+)/;
	if(match = div.className.match(re)) {
		var idx = layer.corners.indexOf(+match[2]);
		var newVal = layer.corners[(idx + step)&3];
		div.className = div.className.replace(re, '$1' + newVal);
		
		div = div.children[0];
		re = /(cubie-corner-orientation-)(\d+)/;
		match = div.className.match(re);
		newVal = (+match[2] + (side!='u' && side!='d') * (step&1) * (1+(idx&1))) % 3;
		div.className = div.className.replace(re, '$1' + newVal);
	}
	
	re = /(cubie-edge-position-)(\d+)/;
	if(match = div.className.match(re)) {
		var idx = layer.edges.indexOf(+match[2]);
		var newVal = layer.edges[(idx + step)&3];
		div.className = div.className.replace(re, '$1' + newVal);
		
		div = div.children[0];
		re = /(cubie-edge-orientation-)(\d+)/;
		match = div.className.match(re);
		newVal = +match[2]^(side=='f' || side=='b')&step;
		div.className = div.className.replace(re, '$1' + newVal);
	}
}


/**	Generates and executes random move */
var sides = ['u','f','r','l','b','d'];
// var nextMove = ();

const Cube = ({ className, style = {} }) => {
  const sceneElem = useRef(null)
  const cubeElem = useRef(null)
  const moveTimeout = useRef()
  const prevSide = useRef(``)

  const nextMove = useCallback(function(e) {
    if (e && e.target.tagName.toLowerCase() === 'use' ) {
      e.stopPropagation();
      return;
    }

    if(document.querySelector('.cube-layer.turn')) return;
    var side = prevSide.current;
    while(side == prevSide.current) side = sides[Math.random()*6|0];
    var step = 1 + (Math.random()*3|0);
    prevSide.current = side;
    moveTimeout.current = setTimeout(function() {move(side+step)}, 1000);
  }, [])

  useEffect(() => {
    if ( ! cubeElem.current ) {
      return
    }

    const layerDivs = Array.from( cubeElem.current.querySelectorAll('.cube-layer') )
    
    layerDivs.forEach( div => {
      div.addEventListener(`transitionend`, updateCubie, true)
      div.addEventListener('transitionend', nextMove, true);
    } )

    nextMove()
    
    return () => {
      layerDivs.forEach( div => {
        div.removeEventListener(`transitionend`, updateCubie, true)
        div.removeEventListener('transitionend', nextMove, true);
      } )

      if ( moveTimeout.current ) {
        clearTimeout(moveTimeout.current)
      }
    }

  }, [nextMove])

  return (
    <div ref={sceneElem} className={`cube-scene lg:cube-scene ${className}`}>
      {}
      <div ref={cubeElem} className="cube">
        {}
        <div className="cube-layer">
          <div className="cubie cubie-corner-position-0">
            <div className="cubie cubie-corner-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-u">
                <Icon nosize className="object-contain object-center" size="80%" id={Icon_ES6.id} secondary={Icon_ES6_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-f">
                <Icon nosize className="object-contain object-center" size="80%" id={Icon_Webpack.id} secondary={Icon_Webpack_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r cubie-sticker sticker-r">
                <Icon nosize className="object-contain object-center" size="80%" id={Icon_Node.id} secondary={Icon_Node_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-corner-position-1">
            <div className="cubie cubie-corner-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-u">
                <Icon nosize className="object-contain rotate-270" size="100%" id={Icon_Nuxt.id} secondary={Icon_Nuxt_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-l">
                <Icon nosize className="object-contain" size="100%" id={Icon_JamStack.id} secondary={Icon_JamStack_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r cubie-sticker sticker-f">
                <Icon nosize className="object-contain" size="100%" id={Icon_Laravel.id} secondary={Icon_Laravel_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-corner-position-2">
            <div className="cubie cubie-corner-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-u">
                <Icon nosize className="object-contain rotate-90" size="100%" id={Icon_Gatsby.id} secondary={Icon_Gatsby_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-r">
                <Icon nosize className="object-contain" size="100%" id={Icon_WP.id} secondary={Icon_WP_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r cubie-sticker sticker-b">
                <Icon nosize className="object-contain" size="100%" id={Icon_Sass.id} secondary={Icon_Sass_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-corner-position-3">
            <div className="cubie cubie-corner-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-u">
                <Icon nosize className="object-contain rotate-180" size="100%" id={Icon_GraphQL.id} secondary={Icon_GraphQL_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-b">
                <Icon nosize className="object-contain" size="100%" id={Icon_Laravel.id} secondary={Icon_Laravel_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r cubie-sticker sticker-l">
                <Icon nosize className="object-contain" size="100%" id={Icon_GraphQL.id} secondary={Icon_GraphQL_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-corner-position-4">
            <div className="cubie cubie-corner-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-d">
                <Icon nosize className="object-contain rotate-90" size="100%" id={Icon_ES6.id} secondary={Icon_ES6_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-r">
                <Icon nosize className="object-contain rotate-180" size="100%" id={Icon_Woocommerce.id} secondary={Icon_Woocommerce_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r cubie-sticker sticker-f">
                <Icon nosize className="object-contain rotate-180" size="100%" id={Icon_Gatsby.id} secondary={Icon_Gatsby_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-corner-position-5">
            <div className="cubie cubie-corner-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-d">
                <Icon nosize className="object-contain rotate-180" size="100%" id={Icon_Tailwind.id} secondary={Icon_Tailwind_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-f">
                <Icon nosize className="object-contain rotate-180" size="100%" id={Icon_WP.id} secondary={Icon_WP_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r cubie-sticker sticker-l">
                <Icon nosize className="object-contain rotate-180" size="100%" id={Icon_SLS.id} secondary={Icon_SLS_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-corner-position-6">
            <div className="cubie cubie-corner-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-d">
                <Icon nosize className="object-contain" size="100%" id={Icon_Node.id} secondary={Icon_Node_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-b">
                <Icon nosize className="object-contain rotate-180" size="100%" id={Icon_Vue.id} secondary={Icon_Vue_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r cubie-sticker sticker-r">
                <Icon nosize className="object-contain rotate-180" size="100%" id={Icon_Laravel.id} secondary={Icon_Laravel_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-corner-position-7">
            <div className="cubie cubie-corner-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-d">
                <Icon nosize className="object-contain rotate-270" size="100%" id={Icon_Webpack.id} secondary={Icon_Webpack_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-l">
                <Icon nosize className="object-contain object-center rotate-180" size="80%" id={Icon_Webpack.id} secondary={Icon_Webpack_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r cubie-sticker sticker-b">
                <Icon nosize className="object-contain object-center rotate-180" size="80%" id={Icon_Gatsby.id} secondary={Icon_Gatsby_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        {}
        <div className="cube-layer">
          <div className="cubie cubie-edge-position-0">
            <div className="cubie cubie-edge-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-u">
                <Icon nosize className="object-contain" size="100%" id={Icon_SLS.id} secondary={Icon_SLS_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-f">
                <Icon nosize className="object-contain" size="100%" id={Icon_Vue.id} secondary={Icon_Vue_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r" />
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-edge-position-1">
            <div className="cubie cubie-edge-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-u">
                <Icon nosize className="object-contain rotate-270" size="100%" id={Icon_Bootstrap.id} secondary={Icon_Bootstrap_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-l">
                <Icon nosize className="object-contain" size="100%" id={Icon_Node.id} secondary={Icon_Node_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r" />
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-edge-position-2">
            <div className="cubie cubie-edge-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-u">
                <Icon nosize className="object-contain" size="100%" id={Icon_React.id} secondary={Icon_React_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-r">
                <Icon nosize className="object-contain" size="100%" id={Icon_JamStack.id} secondary={Icon_JamStack_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r" />
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-edge-position-3">
            <div className="cubie cubie-edge-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-u">
                <Icon nosize className="object-contain rotate-180" size="100%" id={Icon_Sass.id} secondary={Icon_Sass_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-b">
                <Icon nosize className="object-contain" size="100%" id={Icon_JamStack.id} secondary={Icon_JamStack_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r" />
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-edge-position-4">
            <div className="cubie cubie-edge-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-d">
                <Icon nosize className="object-contain" size="100%" id={Icon_React.id} secondary={Icon_React_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-f">
                <Icon nosize className="object-contain rotate-180" size="100%" id={Icon_GraphQL.id} secondary={Icon_GraphQL_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r" />
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-edge-position-5">
            <div className="cubie cubie-edge-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-d">
                <Icon nosize className="object-contain rotate-270" size="100%" id={Icon_Woocommerce.id} secondary={Icon_Woocommerce_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-l">
                <Icon nosize className="object-contain rotate-180" size="100%" id={Icon_Woocommerce.id} secondary={Icon_Woocommerce_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r" />
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-edge-position-6">
            <div className="cubie cubie-edge-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-d">
                <Icon nosize className="object-contain rotate-90" size="100%" id={Icon_JamStack.id} secondary={Icon_JamStack_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-r">
                <Icon nosize className="object-contain rotate-180" size="100%" id={Icon_SLS.id} secondary={Icon_SLS_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r" />
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-edge-position-7">
            <div className="cubie cubie-edge-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-d">
                <Icon nosize className="object-contain" size="100%" id={Icon_Jest.id} secondary={Icon_Jest_Onetone.id} />
              </div>

              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-b">
                <Icon nosize className="object-contain rotate-180" size="100%" id={Icon_WP.id} secondary={Icon_WP_Onetone.id} />
              </div>

              <div data-uniqid={uniqueId()} className="cubie-face face-r" />
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-edge-position-8">
            <div className="cubie cubie-edge-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-f">
                <Icon nosize className="object-contain rotate-90" size="100%" id={Icon_Bootstrap.id} secondary={Icon_Bootstrap_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-r">
                <Icon nosize className="object-contain rotate-90" size="100%" id={Icon_Tailwind.id} secondary={Icon_Tailwind_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r" />
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-edge-position-9">
            <div className="cubie cubie-edge-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-f">
                <Icon nosize className="object-contain rotate-270" size="100%" id={Icon_Sass.id} secondary={Icon_Sass_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-l">
                <Icon nosize className="object-contain rotate-270" size="100%" id={Icon_Tailwind.id} secondary={Icon_Tailwind_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r" />
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-edge-position-10">
            <div className="cubie cubie-edge-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-b">
                <Icon nosize className="object-contain rotate-270" size="100%" id={Icon_Bootstrap.id} secondary={Icon_Bootstrap_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-r">
                <Icon nosize className="object-contain rotate-270" size="100%" id={Icon_Nuxt.id} secondary={Icon_Nuxt_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r" />
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-edge-position-11">
            <div className="cubie cubie-edge-orientation-0">
              <div data-uniqid={uniqueId()} className="cubie-face face-u cubie-sticker sticker-b">
                <Icon nosize className="object-contain rotate-90" size="100%" id={Icon_Jest.id} secondary={Icon_Jest_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-f cubie-sticker sticker-l">
                <Icon nosize className="object-contain rotate-90" size="100%" id={Icon_Nuxt.id} secondary={Icon_Nuxt_Onetone.id} />
              </div>
              <div data-uniqid={uniqueId()} className="cubie-face face-r" />
              <div data-uniqid={uniqueId()} className="cubie-face face-l" />
              <div data-uniqid={uniqueId()} className="cubie-face face-b" />
              <div data-uniqid={uniqueId()} className="cubie-face face-d" />
            </div>
          </div>
        </div>
        {}
        <div className="cube-layer">
          <div className="cubie cubie-middle-r">
            <div data-uniqid={uniqueId()} className="cubie-face face-r" />
            <div data-uniqid={uniqueId()} className="cubie-face face-l" />
            <div data-uniqid={uniqueId()} className="cubie-face face-f" />
            <div data-uniqid={uniqueId()} className="cubie-face face-b" />
            <div data-uniqid={uniqueId()} className="cubie-face face-u" />
            <div data-uniqid={uniqueId()} className="cubie-face face-d" />
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-middle-l">
            <div data-uniqid={uniqueId()} className="cubie-face face-r" />
            <div data-uniqid={uniqueId()} className="cubie-face face-l" />
            <div data-uniqid={uniqueId()} className="cubie-face face-f" />
            <div data-uniqid={uniqueId()} className="cubie-face face-b" />
            <div data-uniqid={uniqueId()} className="cubie-face face-u" />
            <div data-uniqid={uniqueId()} className="cubie-face face-d" />
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-middle-f">
            <div data-uniqid={uniqueId()} className="cubie-face face-r" />
            <div data-uniqid={uniqueId()} className="cubie-face face-l" />
            <div data-uniqid={uniqueId()} className="cubie-face face-f" />
            <div data-uniqid={uniqueId()} className="cubie-face face-b" />
            <div data-uniqid={uniqueId()} className="cubie-face face-u" />
            <div data-uniqid={uniqueId()} className="cubie-face face-d" />
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-middle-b">
            <div data-uniqid={uniqueId()} className="cubie-face face-r" />
            <div data-uniqid={uniqueId()} className="cubie-face face-l" />
            <div data-uniqid={uniqueId()} className="cubie-face face-f" />
            <div data-uniqid={uniqueId()} className="cubie-face face-b" />
            <div data-uniqid={uniqueId()} className="cubie-face face-u" />
            <div data-uniqid={uniqueId()} className="cubie-face face-d" />
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-middle-u">
            <div data-uniqid={uniqueId()} className="cubie-face face-r" />
            <div data-uniqid={uniqueId()} className="cubie-face face-l" />
            <div data-uniqid={uniqueId()} className="cubie-face face-f" />
            <div data-uniqid={uniqueId()} className="cubie-face face-b" />
            <div data-uniqid={uniqueId()} className="cubie-face face-u" />
            <div data-uniqid={uniqueId()} className="cubie-face face-d" />
          </div>
        </div>
        <div className="cube-layer">
          <div className="cubie cubie-middle-d">
            <div data-uniqid={uniqueId()} className="cubie-face face-r" />
            <div data-uniqid={uniqueId()} className="cubie-face face-l" />
            <div data-uniqid={uniqueId()} className="cubie-face face-f" />
            <div data-uniqid={uniqueId()} className="cubie-face face-b" />
            <div data-uniqid={uniqueId()} className="cubie-face face-u" />
            <div data-uniqid={uniqueId()} className="cubie-face face-d" />
          </div>
        </div>
      </div>
      {}
    </div>
  )
};

export default Cube;
