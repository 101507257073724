import React, { useRef, useMemo, forwardRef, useEffect } from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import styled from 'styled-components'
// import { SquaresBackgroundSVG as SquaresBackgroundSvgBase } from 'components/SquaresBackground'
import WalkingSquaresBackgroundSVG from 'components/WalkingSquares'
// import useMounted from 'hooks/use-mounted'
import { useMediaQueryContext } from "../context/media"
import { Parallax } from 'react-scroll-parallax'
import loadable from '@loadable/component'
import { isSSR } from "../util"
import IntroSection from 'components/home/intro'
import pDefer from 'p-defer'
import { useInView } from "react-intersection-observer"
import TransitionPage from "../transition-page"

export const SquaresBackgroundSvg = styled(props => {
  const breakpoints = useMediaQueryContext()
  
  return (
    <WalkingSquaresBackgroundSVG
      activeWidthFraction={
        breakpoints.lg
          ? breakpoints.xl
            ? breakpoints.xxl
              ? breakpoints.xxxl
                ? 0.15
                : 0.2
              : 0.25
            : 0.3
          : 0.35
      }
      {...props}
    />
  )
})`
  .section-title {
    margin-bottom: .5rem;
  }
`

const lazyloadDefers = {}

const LoadableSection = loadable(props => {
  return lazyloadDefers[props.id].promise.then( () => import(`components/home/${props.id}`) ) 
})

let loadingSections = 0,
    loadedSections = 0

const Section = props => {
  loadingSections++;
  const { id: sectionId } = props

  if (! isSSR()) {
    lazyloadDefers[sectionId] = lazyloadDefers[sectionId] || pDefer()
  }
  
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: `200px`,
  })
  
  // if coming from another route just resolve lazyload defer immidiatelely
  useEffect(() => {
    if (window.history.state) {
      lazyloadDefers[sectionId].resolve()
    }
  }, [sectionId])

  //
  useEffect(() => {
    if (inView) {
      lazyloadDefers[sectionId].resolve()
    }
  }, [inView, sectionId])

  const fallback = useMemo(() => {
    if (! isSSR() && !window.history.state) {
      const htmlElem =
        sectionId && typeof document !== `undefined`
          ? document.querySelector(
              `[data-loadable-fallback="section-${sectionId}"]`
            )
          : null

      if (htmlElem) {
        const TagName = htmlElem.tagName.toLowerCase()
        const { className, id, innerHTML } = htmlElem
        const fallback = (
          <TagName
            ref={ref}
            {...{ className, id }}
            dangerouslySetInnerHTML={{ __html: innerHTML }}
          />
        )

        return fallback
      }

      if (process.env.NODE_ENV === `development`) {
        return <div ref={ref} className="min-h-screen" />
      }

      return null
    }
  }, [ref, sectionId])

  const loadableProps = {
    ...props,
    fallback: fallback
  }

  if (! isSSR() && window.history.state) {
    LoadableSection.load(loadableProps).then(() => {
      loadedSections++;
      
      if (loadingSections === loadedSections) {
        if ( window.location.hash.length > 1 ) {
          window.dispatchEvent( new Event(`hashchange`) )
        }
      }
    })
  }

  return (
    <LoadableSection {...loadableProps} />
  )
}

const IndexPage = ({ data }) => {
  // const mounted = useMounted()
  // const workTitleRef = useRef()
  // const breakpoints = useMediaQueryContext()

  const { page: {
    route,
    meta,
  } } = data

  const sectionParallaxY = useMemo(() => ([`0px`,`-100px`]), [])

  return (
    <TransitionPage className="pt-12 lg:pt-20">
      <SEO pathname={route} titleTemplate={`%s`} {...meta} />

      <IntroSection id="intro" data={data} />

      <Parallax
        y={sectionParallaxY}
        styleInner={{ willChange: "transform" }}
      >
        <Section id="philosophy" data={data} />
      </Parallax>

      <>
        <Section id="clients" data={data} />
      </>
      
      <Parallax
        y={sectionParallaxY}
        styleInner={{ willChange: "transform" }}
      >
        <Section id="projects" data={data} />
      </Parallax>

      <>
        <Section id="our-stack" data={data} />
      </>

      <Parallax
        y={sectionParallaxY}
        styleInner={{ willChange: "transform" }}
      >
        <Section id="dev-diaries" data={data} />
      </Parallax>

      <Section id="contact" data={data} />
    </TransitionPage>
  )
}

export default IndexPage

export const IndexQuery = graphql`
  query IndexQuery {
    page: pageHome {
      ...CMS_Meta

      route
      intro {
        heading
        subHeading
        ctaButton {
          href
          label
        }
      }
      philosophy {
        heading
        columns {
          heading
          content {
            remark: childMarkdownRemark {
              html
            }
          }
        }
        ctaButton {
          href
          label
        }
      }
    }

    ...ClientsFragment
    ...ProjectsFragment
    ...HomeBlogSectionFragment
  }
`
