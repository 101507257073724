import React, { useCallback, useEffect, useMemo } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import TextareaAutosize from 'react-autosize-textarea'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { isSSR } from '../util'
import { string as YupString, object as YupObject } from "yup"
import { theme } from '../twconfig'
import SpinnerSVG from '../images/tail-spin.inline.svg'

const contactSchema = YupObject().shape({
  name: YupString().required('Name is required'),
  email: YupString().email('Please provide a valid email').required('Required'),
  phone: YupString(),
  message: YupString().min(16).required(`Your message is too short.`)
})

const FormField = ({ field, form, className, inputClassName, ...props }) => (
  <div className={`form-field ${className}`}>
    <input className={inputClassName} {...props} {...field} />
    {props.required && <span className="required" />}
  </div>
)

const ContactFormBase = props => {
  const required = true

  useEffect(() => {
    if (isSSR()) {
      return
    }

    if (! window.fetch) {
      import(`unfetch/polyfill`)
    }

    if (! window.URLSearchParams) {
      import(`url-polyfill`)
    }
  }, [])

  const handleSubmit = useCallback(async (values, actions) => {
    try {
      const resp = await fetch(`/`, {
        method: `POST`,
        body: new URLSearchParams(values),
      })

      if ( resp.status !== 200 ) {
        throw new Error(`Sorry, something went wrong`)
      }

      actions.setStatus({
        success: true,
        msg: `Thank you for contacting us! We will get back to you as soon as possible.`,
      })
    } catch(e) {
      
      actions.setStatus({
        success: false,
        msg: `Error: ${e.toString()}`,
      })
    }
      
    actions.setSubmitting(false)
  }, [])  

  const initialValues = useMemo(() => ({
    fullname: ``, // honeypot
    name: ``,
    email: ``,
    phone: ``,
    message: ``,
    'form-name': props.name,
  }), [props.name])

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={contactSchema}>
      {({ isSubmitting, errors, touched, status = {} }) => {

        return (
          <Form {...props}>
            {/* Netlify injects hidden input which is lost when our app mounts. That's why we need to replicate it here. */}
            {!isSSR() && props[`data-netlify`] && props.name ? (
              <Field type="hidden" name="form-name" value={props.name} />
            ) : null}

            {/* honeypot for bots */}
            {props[`netlify-honeypot`] ? (
              <Field name={props[`netlify-honeypot`]}>
                {props => (
                  <FormField
                    type="text"
                    className="hidden"
                    {...props}
                  />
                )}
              </Field>
            ) : null}
              
            {/* field name for humans */}
            <Field name="name">
              {props => (
                <FormField
                  type="text"
                  placeholder="Name"
                  inputClassName={`${errors.name && touched.name ? `invalid` : ``}`}
                  required={required}
                  {...props}
                />
              )}
            </Field>
            <Field name="email">
              {props => (
                <FormField
                  type="email"
                  placeholder="Email"
                  className={`${
                    errors.email && touched.email ? `invalid` : ``
                  }`}
                  required={required}
                  {...props}
                />
              )}
            </Field>
            <Field name="phone">
              {props => (
                <FormField
                  type="tel"
                  placeholder="Phone"
                  className={`${
                    errors.phone && touched.phone ? `invalid` : ``
                  }`}
                  {...props}
                />
              )}
            </Field>
            <Field name="message">
              {({ field, form }) => (
                <div className="form-field">
                  <TextareaAutosize
                    {...field}
                    rows={3}
                    maxRows={15}
                    async={true}
                    placeholder="Message"
                    className={`${
                      errors.message && touched.message ? `invalid` : ``
                    }`}
                    required={required}
                    />
                  <span className="required" />
                </div>
              )}
            </Field>
            
            {status.success ? (
              <div className="bg-green-300 border-green-500 text-green-800 border p-4 mb-4">
                {status.msg}
              </div>
            ) : (
              <>
                {(! status.success && status.msg) ? (
                  <div className="bg-red-300 border-red-500 text-red-800 border p-4 mb-4">
                    {status.msg}
                  </div>
                ) : null}
                <button
                  type="submit"
                  className="btn btn-lg w-full text-gray-600"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <SpinnerSVG className="inline-block align-middle w-6 h-6" />
                  ) : `Submit`}
                </button>
              </>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

const ContactForm = styled(ContactFormBase)`
  ${tw`text-gray-400`}
  
  input:not([type='hidden']),
  input:not([type='radio']),
  input:not([type='checkbox']),
  select,
  textarea {
    ${tw`border-gray-200`}

    &.invalid {
      ${tw`border-red-500`}
    }
    
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      ${tw`text-gray-400`}
      transition: all 200ms ease-in-out;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      ${tw`text-gray-400`}
      transition: all 200ms ease-in-out;
    }
    :-ms-input-placeholder { /* IE 10+ */
      ${tw`text-gray-400`}
      transition: all 200ms ease-in-out;
    }
    :-moz-placeholder { /* Firefox 18- */
      ${tw`text-gray-400`}
      transition: all 200ms ease-in-out;
    }
  }

  textarea {
    min-height: 5rem;
    resize: vertical;
  }

  input:not([type='hidden']):focus,
  input:not([type='radio']):focus,
  input:not([type='checkbox']):focus,
  select:focus,
  textarea:focus {
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      ${tw`text-gray-200`}
    }
    ::-moz-placeholder { /* Firefox 19+ */
      ${tw`text-gray-200`}
    }
    :-ms-input-placeholder { /* IE 10+ */
      ${tw`text-gray-200`}
    }
    :-moz-placeholder { /* Firefox 18- */
      ${tw`text-gray-200`}
    }
  }

  .form-field {
    position: relative;
  }

  .required {
    position: absolute;
    top: 0;
    left: 100%;
    
    border-width: 1.25rem;
    border-top-width: 0;
    border-color: transparent;
    border-bottom-color: ${theme.colors.gray[`200`]};
    pointer-events: none;
    user-select: none;
    transform: translateX(-50%) rotate(45deg);
    transform-origin: 50% 0;

    &::after {
      content: '*';
      position: absolute;
      left: 50%;
      ${tw`text-xl font-semibold text-red-500`}
      /* top: 100%; */
      transform: rotate(-45deg);
    }

  }

  .invalid + .required {
    border-bottom-color: ${theme.colors.red[`500`]};
    &::after {
      color: ${theme.colors.white};
    }
  } 

`

export default ContactForm
