import React from 'react'
import CubeBase from 'components/Cube'
import styled from 'styled-components'
// import useGatsbyData from '../../hooks/use-gatsby-data'
import viewportMotion from '../viewport-motion'
import { motion } from 'framer-motion'
import { theme } from '../../twconfig'
import { SquaresBackgroundSvg } from '../../templates/home'
import { motionTransition } from '../../util'
import tw from 'tailwind.macro'

const Cube = ({ variants, transition, className, ...props }) => {
  const motionProps = { variants, transition }

  return (
    <motion.div className={className} {...motionProps}><CubeBase {...props} /></motion.div>
  )
}

const viewportEnterInnerTransition = {
  ...motionTransition,
  mass: .75,
  stiffness: 10,
}

const StyledCube = styled(Cube)`
  /* margin-right: 30vw;
  margin-left: auto;
  width: 0!important;
  height: 0!important; */
  margin-top: auto;
  margin-bottom: auto;

  padding-top: 15vh;
  padding-bottom: 28vh; 
  
  @media (min-width: ${theme.screens.sm}) {
    padding-top: 20vh;
  }
  
  @media (min-width: ${theme.screens.md}) {
    padding-top: 22vh;
    padding-bottom: 35vh; 
  }

  @media (min-width: ${theme.screens.lg}) {
    /* margin-top: 23vh;
    margin-bottom: 35vh; */
  }

  @media (min-width: ${theme.screens.xxl}) and (max-width: ${parseInt(theme.screens.xxxl) - 1}px) and (min-aspect-ratio: 9/5) {
    padding-top: 150px !important;
    padding-bottom: 300px !important;
  }
`

const IntroSubHeading = styled.small``

const IntroHeading = styled.h2`
  max-width: 38rem;
  margin-bottom: 0;
  
  ${IntroSubHeading} {
    ${tw`text-xl`}
  }
`


const introEnterViewportVariants = {
  visible: {
    transition: {
      staggerChildren: .6,
      staggerDirection: -1,
    }
  },
  hidden: {}
}

const enterViewportChildrenVariants = {
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
  },
  hidden: {
    opacity: 0,
    x: 0,
    y: 30,
  }
}

const IntroSectionBase = ({ id, data, className, ...props }) => (
  <section className={`hero ${className}`} data-loadable-fallback={`section-${id}`}>
    <viewportMotion.div
      variants={introEnterViewportVariants}
      className="container flex flex-col justify-center items-center"
    >
      {data.heading && (
        <motion.div
          variants={enterViewportChildrenVariants}
          transition={{ ...viewportEnterInnerTransition, delayChildren: 1 }}
        >
          <SquaresBackgroundSvg
            rows={3}
            width={136}
            minWidthPerSquare={33}
            padding={0}
            className="pt-16"
            svgClassName="right-0 top-0"
          >
            <IntroHeading className="text-4xl md:text-5xl leading-tight uppercase text-center">
              {data.heading}
              {data && data.subHeading && (
                <IntroSubHeading className="block my-4 font-normal text-gray-400 normal-case leading-tight">
                  {data.subHeading}
                </IntroSubHeading>
              )}
            </IntroHeading>
          </SquaresBackgroundSvg>

          {data.ctaButton && (
            <motion.div
              variants={enterViewportChildrenVariants}
              transition={viewportEnterInnerTransition}
              className="text-center my-4"
            >
              <a
                className="btn btn-lg btn-cta align-top"
                href={data.ctaButton.href}
              >
                {data.ctaButton.label}
              </a>
            </motion.div>
          )}
        </motion.div>
      )}

      <StyledCube
        variants={enterViewportChildrenVariants}
        transition={viewportEnterInnerTransition}
        className="mx-auto"
      />
    </viewportMotion.div>
  </section>
)


const IntroSection = styled(IntroSectionBase)`
  height: calc(100vh - 3rem);

  @media (min-width: ${theme.screens.lg}) {
    height: calc(100vh - 5rem);
  }
`

const IntroSectionWithData = ({ data: { page: { intro }}, ...props }) => {
  return (
    <IntroSection {...props} data={intro} />
  )
}

export default IntroSectionWithData
