import React, { useState, useEffect, useLayoutEffect, useRef, useCallback, useMemo } from 'react'
import { inRange ,random } from 'lodash'
import useMounted from 'hooks/use-mounted'
import { useWindowSize, useInterval } from 'rooks'
import { useInView } from 'react-intersection-observer'
// import { intersectThresholds } from '../util'
import { Polygon, Point } from '@mathigon/fermat'
import styled from 'styled-components'
import raf from 'raf'

const generateGroupOpacityMap = () => {
  const transStart = random(0,3),
        transEndMax = 4 - transStart,
        transEnd = random(transStart, transStart + transEndMax),
        transRange = [transStart, transEnd]

  const map = []

  for (let i = 0; i < 4; i++) {
    map[i] = inRange(i, ...transRange) ? 0 : 1
  }

  return map
}

const SquareGroup = ({
  translate = [0, 0],
  fill = `rgba(0,0,0,0)`,
  isVisible,
  className = ``,
  style = {},
}) => {
  const [opacityMap, setOpacityMap] = useState(generateGroupOpacityMap())
  const updateOpacityMap = useCallback(() => setOpacityMap(() => generateGroupOpacityMap()), [])

  const { start: startInterval, stop: stopInterval } = useInterval(
    updateOpacityMap,
    3000
  )

  useEffect(() => {
    isVisible ? startInterval() : stopInterval()

    return () => {
      stopInterval()
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <g
      className={`square ${className ? `fill-current stroke-current ${className}` : ``}`}
      transform={`translate(${translate[0]}, ${translate[1]})`}
      // style={{ fill, stroke: fill, strokeWidth: 1 }}
    >
      <path
        //  className="top-left"
        style={{ opacity: opacityMap[0] }}
        d="M 0,7 7,7 7,0 Z"
      />
      <path
        //  className="top-right"
        style={{ opacity: opacityMap[1] }}
        d="m 7,0 0,7 7,0 z"
      />
      <path
        //  className="bottom-left"
        style={{ opacity: opacityMap[2] }}
        d="M 14,7 7,7 7,14 Z"
      />
      <path
        //  className="bottom-right"
        style={{ opacity: opacityMap[3] }}
        d="M 7,14 7,7 0,7 Z"
      />
    </g>
  )
}

const generateRange = ( rangeLen, total ) => {
  let rangeStart = random(0, total - rangeLen - 1)
  let rangeEnd = rangeStart + rangeLen

  return [rangeStart, rangeEnd]
}

const SquaresSVGBase = ({
  contentsRef,
  fillColor = `gray-600`,
  activeFillColor = `brand`, 
  width = null,
  rows = 3,
  padding = 15,
  minWidthPerSquare = 36,
  activeWidthFraction = .25,
  id,
  children,
  ...props
}) => {
  const rootRef = useRef()
  const initWidth = useRef(width)

  const [canvasWidth, setCanvasWidth] = useState(initWidth.current || 0)
  
  const { innerWidth: winWidth, innerHeight: winHeight } = useWindowSize()
  
  const [intersectObserveTarget, isVisible, intersectionEntry] = useInView({
    threshold: .01,
  })

  // if (HOCroot) {
  //   rootNode(HOCroot)
  // }

  const [greyRange, setGreyRange] = useState([0, 0])

  const actualWidth = 12
  const perRow = Math.max(
    1,
    Math.floor((canvasWidth - padding * 2) / minWidthPerSquare)
  )
  const widthPerGroup = (canvasWidth - padding * 2) / perRow
  const height = Math.max(widthPerGroup * rows + padding * 2, 0)
  const totalGroups = rows * perRow
  const greyRangeLen = Math.ceil(totalGroups / 2.5)

  /** 
   * Scroll feedback effect attempt
   */
  const [positionProgress, setPositionProgress] = useState(0)
  
  // const intersectRatio = useMemo(
  //   () => (intersectionEntry && intersectionEntry.intersectionRatio) || 0,
  //   [intersectionEntry]
  // )

  useEffect(() => {
    // console.log(`intersectionEntry: `, intersectionEntry)
    if ( ! intersectionEntry ) {
      return
    }
    
    const { isIntersecting } = intersectionEntry

    function handleScroll() {
      raf(() => {
        if ( ! rootRef.current ) {
          return
        }
        
        const rect = rootRef.current.getBoundingClientRect()
        const progress = (1 - Math.max(Math.min(rect.top, winHeight), 0) / winHeight)
        setPositionProgress( progress )
      })
    }

    if (isIntersecting) {
      window.addEventListener(`scroll`, handleScroll)
    } else {
      window.removeEventListener(`scroll`, handleScroll)
    }
    
    return () => window.removeEventListener(`scroll`, handleScroll)
  }, [id, intersectionEntry, isVisible, winHeight])


  const shapeWidth = useMemo(() => perRow * ((1 + positionProgress) * activeWidthFraction), [activeWidthFraction, perRow, positionProgress])
  const shapeWidthEven = useMemo(() => Math.ceil(shapeWidth) % 2 ? Math.ceil(shapeWidth) : Math.floor(shapeWidth), [shapeWidth])

  const activeShape = useMemo(() => {
    const minDeg = 5,
          maxDeg = 25,
          slopePerWidth = (maxDeg - minDeg) / 1280,
          slopeDeg = maxDeg - (( Math.min(winWidth - 320, 1280) ) * slopePerWidth)

    const slopeDeltaX = rows / Math.tan(slopeDeg)

    const bottomLeft = [0, rows],
          bottomRight = [shapeWidthEven - slopeDeltaX, rows],
          topRight = [shapeWidthEven, 0],
          topLeft = [slopeDeltaX, 0]

    const points = [bottomLeft, bottomRight, topRight, topLeft, bottomLeft]

    return points
  }, [rows, shapeWidthEven, winWidth])

  const positionPolygon = useMemo(() => {
    const minTraslateX = shapeWidthEven / -1,
          maxTranslateX = perRow - shapeWidthEven,
          translateX = minTraslateX + positionProgress * (maxTranslateX - minTraslateX)

    const points = activeShape.map( ([x, y]) => new Point( x + translateX, y ) )
    return new Polygon(...points)
  },[activeShape, perRow, positionProgress, shapeWidthEven])

  useLayoutEffect(() => {
    if ( initWidth.current !== null ) {
      return
    }

    if (contentsRef.current) {
      setCanvasWidth(contentsRef.current.offsetWidth)
    }
  }, [initWidth, winWidth, setCanvasWidth, contentsRef])

  function renderGroups() {
    const groups = []
    let transY, transX

    for (let x = 0; x < perRow; x++) {
      transX = padding + x * widthPerGroup + (widthPerGroup - actualWidth) / 2

      for (let y = 0; y < rows; y++) {
        transY = padding + y * widthPerGroup + (widthPerGroup - actualWidth) / 2
        const active = positionPolygon.contains({ x, y })
        let fill = active ? fillColor : `transparent` 

        groups.push(
          <SquareGroup
            className={`${active ? 'active' : ''} ${fill ? `text-${fill}` : ''}`}
            isVisible={isVisible}
            key={`group-${x}x${y}`}
            translate={[transX, transY]}
          />
        )
      }
    }

    return groups
  }

  return (
    <svg
      ref={(elem) => { intersectObserveTarget(elem); rootRef.current = elem }}
      width={canvasWidth}
      height={height}
      viewBox={`0 0 ${canvasWidth} ${height}`}
      {...props}
    >
      {renderGroups()}
    </svg>
  )
}

const SquaresSVG = styled(SquaresSVGBase)`
  path {
    transition: all .6s ease-in-out;
  }
  
  .active path {
    transition-duration: 1.5s;
  }
`

const WalkingSquaresBackgroundSVG = ({ children, className = ``, svgClassName = ``, ...props }) => {
  const contentsRef = useRef()
  const mounted = useMounted()

  return (
    <div className={`relative svgbg ${className}`}>
      {(mounted && <SquaresSVG contentsRef={contentsRef} className={`${svgClassName} absolute bottom-0`} {...props} />) || null}

      <div ref={contentsRef} className="relative overflow-hidden">
        {children}
      </div>
    </div>
  )
}

export default WalkingSquaresBackgroundSVG
