import React, { useCallback, useMemo } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { motionTransition } from '../util'
import useMounted from '../hooks/use-mounted'

const charVariants = {
  _visible(idx) {
    return {

    }
  },

  hidden: {
    x: `1rem`,
    opacity: 0,
  },
}

function getVisibleVariantCallback(transition) {
  return (idx) => ({
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      ...transition,
      delay: idx * .075,
    }
  }) 
}

const forceInlineBlock = { display: `inline-block` }

const TextTransition = ({ text = ``, visibleVariantName = `visible`, transition: userTransition = {}, ...props }) => {
  const chars = text.split(``)
  const mounted = useMounted()

  const transition = useMemo(() => ({
    ...motionTransition,
    ...userTransition,
  }), [userTransition])

  const variants = useMemo(() => {
    const { _visible, ...variants } = charVariants

    return {
      ...variants,
      [visibleVariantName]: getVisibleVariantCallback(transition),
    }
  }, [transition, visibleVariantName])

  // const motionProps = useMemo(() => ({
  //   initial: `hidden`,
  //   animate: `enter`,
  //   transition: motionTransition,
  // }), [])

  return chars ? (
    <>
      {mounted ? (
        chars.map((char, idx) => {
          return (
            <motion.span
              style={forceInlineBlock}
              key={`text-${idx}`}
              variants={variants}
              initial="hidden"
              transition={transition}
              custom={idx}
              {...props}
            >
              {char.replace(/^\s$/g, `\u00A0`)}
            </motion.span>
          )
        })
      ) : <span style={{ opacity: 0 }}>{text}</span>}
    </>
  ) : null
}

export default TextTransition
