import React from "react"

const Icon = ({ id, secondary, size = `1em`, className, nosize, ...props }) => (
  <svg
    className={`inline-block ${className}`}
    {...props}
    style={nosize ? {} : { width: size, height: size }}
    dangerouslySetInnerHTML={{
      __html: `
        <use class="primary" xlink:href="#${id}" fill="currentColor"></use>
        ${secondary
          ? `<use class="secondary" xlink:href="#${secondary}" fill="currentColor"></use>`
          : null
        }`,
    }}
  />
)

Icon.defaultProps = {
  className: ``,
}

export default Icon
