import React from 'react'
import styled from 'styled-components'

const Container = styled(({ className, ...props }) => (
  <div className={`container ${className}`} {...props} />
))`
  max-width: 1200px !important;
`

export default Container
