/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {  useMemo } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { get as objGet } from 'lodash'
// import { GatsbyPageContext } from 'context/page'

function getOgImageMetaFromSharpFixed({ src, width, height }, siteUrl) {
  return [
    { property: `og:image`, content: `${siteUrl}${src}` },
    { property: `og:image:width`, content: width },
    { property: `og:image:height`, content: height },
  ]
}

function SEO({ pathname, description, lang, ogimage, title, children, meta, ...props }) {
  const {
    site: {
      siteMetadata: {
        title: siteTitle,
        description: siteDescription,
        ogImage: defaultOgImage,
        siteUrl,
      }
    }
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          siteUrl
          ogImage {
            sharp: childImageSharp {
              fixed(width: 1200, height: 1200, cropFocus: CENTER, quality: 100) {
                src
                width
                height
              }
            }
          }
        }
      }
    }
  `)

  const pageTitle = title || siteTitle
  const pageDescription = objGet(description, [`remark`, `content`], ``)

  const ogImage = ogimage && ogimage.sharp ? getOgImageMetaFromSharpFixed(ogimage.sharp.fixed, siteUrl) : (
    defaultOgImage ? getOgImageMetaFromSharpFixed(defaultOgImage.sharp.fixed, siteUrl) : [] 
  )

  const hemletMeta = useMemo(() => {
    const meta = [
      {
        name: `description`,
        content: pageDescription,
      },
      {
        property: `og:title`,
        content: pageTitle,
      },
      {
        property: `og:description`,
        content: pageDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        property: `og:url`,
        content: `${siteUrl}${pathname}`,
      }
    ]

    if (ogImage) {
      meta.push(...ogImage)
    }

    return meta
  }, [ogImage, pageDescription, pageTitle, pathname, siteUrl])

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      titleTemplate={ title ? `%s - ${siteTitle}` : `%s` }
      meta={hemletMeta}
      {...props}
    >
      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.object,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
