import React, { useContext, useEffect, useCallback, useRef, useMemo } from 'react'
// import { useLayoutContext } from 'context/layout'
import { motion, MotionContext } from 'framer-motion'
import { debounce } from 'lodash'
// import useWhyDidYouUpdate from 'hooks/use-why-update'

export const transition = {
  type: `spring`,
  damping: 30,
  sniffness: 10,
  mass: 1,
  restSpeed: .5,
  restDelta: .5,
}

const variants = {
  enter: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      ...transition,
      when: {
        beforeChildren: true,
      }
    },

    transitionEnd: {
      position: `relative`,
    }
  },
  
  init: {
    opacity: 0,
    x: 0,
    y: `15%`,
    position: `relative`,
    zIndex: 100,
    left: 0,
  },

  exit: {
    opacity: 0, 
    x: 0,
    transition,
    y: `-15%`,
    width: `100%`,
    position: `absolute`,
    zIndex: 0,
  },
}

let didAnimateOnce = false

const TransitionPage = React.memo(({className = ``, style = {}, template, ...props}) => {
  const motionCustom = useRef({ scrollY: 0 })
  const { onAnimationStart, onAnimationComplete, exit: pageExit = `exit` } = props

  const { exitProps } = useContext(MotionContext)
  const isExiting = exitProps && exitProps.isExiting

  const animationStartHandler = useCallback(() => {
    if ( ! didAnimateOnce ) {
      didAnimateOnce = true
    }

    onAnimationStart && onAnimationStart()
  }, [onAnimationStart])

  const animationCompleteHandler = useCallback(() => {
    window.dispatchEvent( new Event(`resize`) )
    onAnimationComplete && onAnimationComplete()
  },[onAnimationComplete])

  const exit = useMemo(() => pageExit,[pageExit])

  useEffect(() => {
    function scrollHandler() {
      motionCustom.current.scrollY = window.scrollY
    }

    const handleScroll = debounce(scrollHandler, 50)

    window.addEventListener(`scroll`, handleScroll, false)
    return () => window.removeEventListener(`scroll`, handleScroll, false)
  }, [])

  const styles = useMemo(() => {
    const result = {
      ...style,
      top:
        typeof window !== `undefined` && isExiting
          ? motionCustom.current.scrollY * -1
          : 0,
    }

    return result
  }, [isExiting, style])

  return (
    <motion.main
      style={styles}
      initial="init"
      animate="enter"
      variants={variants}
      className={`${className} bg-black flex-grow flex flex-col overflow-hidden`}
      {...props}
      
      exit={exit}
      onAnimationStart={animationStartHandler}
      onAnimationComplete={animationCompleteHandler}
    />
  )
})

// TransitionPage.displayName = `TransitionPage`
// TransitionPage.whyDidYouRender = true

export default TransitionPage
