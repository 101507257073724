
import React, { useState, useEffect, useMemo } from 'react'
// import { useScrollPercentage } from 'react-scroll-percentage'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { intersectThresholds } from '../util'
import { useMediaQueryContext } from '../context/media'
import { theme } from '../twconfig'
import tw from 'tailwind.macro'
import { useWindowSize } from 'rooks'
// import tw from 'tailwind.macro'

const StyledWrapper = styled.div`
  position: relative;
  height: 100%;
  z-index: 1;

  &::before {
  }

  .btn {
    ${tw`text-gray-600`}
  }

  /* &:last-child {
  } */

  & + & {
    ${tw`-mt-8`}
  }
`

const AnimatedBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  ${tw`bg-gray-600`}
  /* transform-origin: 0 0; */
`

// const SkewedBackground = motion.div

const SkewBackground = ({ children, className = ``, bgColor = theme.colors[`800`], ...props}) => {
  // const [ref, percentage] = useScrollPercentage({
  //   /* Optional options */
  //   threshold: .33,
  // })

  const breakpoints = useMediaQueryContext()
  const { innerHeight: winHeight } = useWindowSize()

  const [ref, inView, intersectionEntry] = useInView({
    threshold: intersectThresholds
    ,
  })

  // const intersectRatio = useMemo(
  //   () => {
  //     console.log(`intersectionEntry: `, intersectionEntry)
  //     return (intersectionEntry && intersectionEntry.intersectionRatio) || 0
  //   },
  //   [intersectionEntry]
  // )

  const skewY = useMemo(() => {
    if (! winHeight || ! intersectionEntry) {
      return `0deg`
    }

    const maxRotation = breakpoints.xxxl ? -3 : (breakpoints.lg ? -4 : -8)
    const fraction = 1 - ( Math.min( Math.max(intersectionEntry.boundingClientRect.top - winHeight/2, 0), (winHeight / 2)) / (winHeight/2) )
    
    return `${maxRotation * (fraction)}deg`
  }, [winHeight, intersectionEntry, breakpoints.xxxl, breakpoints.lg])

  // const motionValue = useMotionValue(intersectRatio)
  
  // const skewY = useTransform(motionValue, value => {
  //   return `${-15 * (1 - value)}deg`
  // })

  // const springControl = useSpring(motionValue, motionTransition)
  // cosnt { }

  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])

  // const contrastColor = useMemo(() => Color(bgColor).isLight() ? theme.colors.gray[`600`] : undefined, [bgColor] )

  return (
    <StyledWrapper className={`${className} section-skew`} {...props}>
      <AnimatedBackground style={{ transform: `skewY(${skewY})`, backgroundColor: bgColor }} />
      
      {/* {mounted && <RoratingBackground className="z-20" scrollPercent={percentage} bgColor={bgColor} />} */}
      {/* {mounted && <RoratingBackgroundTop className="z-20" scrollPercent={percentage} />}
      {mounted && <RoratingBackgroundBottom className="z-20" scrollPercent={percentage} />} */}

      <div ref={ref} className="relative z-20">
        {children}
      </div>
    </StyledWrapper>
  )
}

export default SkewBackground
