import React, { useMemo, useEffect, useState, useCallback } from 'react'

import { motion } from 'framer-motion'
import { useInViewport } from 'react-in-viewport'
import { useInView } from 'react-intersection-observer'
import { motionTransition as transitionBase } from '../util'
import { useRef } from 'react'

const visible = {
  opacity: 1,
  x: 0,
  y: 0,
}

const hidden = {
  opacity: 0,
  x: 0,
  y: 30,
}

const defaultVariants = {
  visible,
  hidden,
}

function createComponent(tag) {
  const Motion = motion[tag]

  return ({
    inViewportOptions = { threshold: 0.5 },
    variants: userVariants = {},
    transition: userTransition = {},
    className = ``,
    ...props
  }) => {
    const [entered, setEntered] = useState(false)
    const handleAnimationComplete = useCallback(() => setEntered(true), [])

    Object.assign(inViewportOptions, {
      triggerOnce: true,
    })

    const [ref, isVisible] = useInView(inViewportOptions)

    const variants = useMemo(() => ({
      ...defaultVariants,
      ...userVariants
    }), [userVariants])

    const transition = useMemo(
      () => ({ ...transitionBase, ...userTransition }),
      [userTransition]
    )

    const [activeVariant, setActiveVariant] = useState(`hidden`)

    useEffect(() => {
      if ( isVisible && activeVariant === `hidden` ) {
        setActiveVariant(`visible`)
      }
    }, [isVisible, activeVariant])
    // const activeVariant = useMemo(() => enterCount > 0 ? `visible` : `hidden`, [enterCount])

    return (
      <Motion
        ref={ref}
        variants={variants}
        transition={transition}
        animate={activeVariant}
        initial="hidden"
        onAnimationComplete={handleAnimationComplete}
        className={`${className} ${entered ? `entered` : `` }`}
        {...props}
      />
    )
  }
}

export default Object.keys(motion).reduce( (acc, tag) => {
  acc[tag] = createComponent(tag)
  return acc
}, {})

export { defaultVariants }
